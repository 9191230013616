ion-button {
  --padding-bottom: 6px;
  --padding-top: 6px;
  --padding-start: 49px;
  --padding-end: 49px;
  --border-color: transparent;
  --border-width: 1px;
  --border-style: solid;
  --color: white;
  --border-radius: 3px;
  font-family: 'Roboto-Medium';
  font-size: 18px;
  line-height: 18px;
  height: 40px;
}

ion-modal.document-agreement,
ion-modal.document-redirect {
  --height: 100%;
  --height: 100%;
  --width: 100%;
  --border-radius: 0;
}

ion-modal.return-confirmation {
  --min-width: 767px;
  --height: 500px;
}

ion-content.non-scrollable {
  --overflow: hidden;
}
