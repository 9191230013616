// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --ion-color-green: #23775c;
  --ion-color-green-rgb: 35, 119, 92;
  --ion-color-green-contrast: #ffffff;
  --ion-color-green-contrast-rgb: 255, 255, 255;
  --ion-color-green-shade: #1f6951;
  --ion-color-green-tint: #39856c;

  /** Custom styles for buttons **/
  .ion-color-green {
    --ion-color-base: var(--ion-color-green);
    --ion-color-base-rgb: var(--ion-color-green-rgb);
    --ion-color-contrast: var(--ion-color-green-contrast);
    --ion-color-contrast-rgb: var(--ion-color-green-contrast-rgb);
    --ion-color-shade: var(--ion-color-green-shade);
    --ion-color-tint: var(--ion-color-green-tint);
  }

  --ion-color-brown: #af9b78;
  --ion-color-brown-rgb: 175, 155, 120;
  --ion-color-brown-contrast: #fff;
  --ion-color-brown-contrast-rgb: 0, 0, 0;
  --ion-color-brown-shade: #9a886a;
  --ion-color-brown-tint: #b7a586;

  .ion-color-brown {
    --ion-color-base: var(--ion-color-brown);
    --ion-color-base-rgb: var(--ion-color-brown-rgb);
    --ion-color-contrast: var(--ion-color-brown-contrast);
    --ion-color-contrast-rgb: var(--ion-color-brown-contrast-rgb);
    --ion-color-shade: var(--ion-color-brown-shade);
    --ion-color-tint: var(--ion-color-brown-tint);
  }

  --ion-color-light-blue: #75b5d8;
  --ion-color-light-blue-rgb: 117, 181, 216;
  --ion-color-light-blue-contrast: #fff;
  --ion-color-light-blue-contrast-rgb: 0, 0, 0;
  --ion-color-light-blue-shade: #679fbe;
  --ion-color-light-blue-tint: #83bcdc;

  .ion-color-light-blue {
    --ion-color-base: var(--ion-color-light-blue);
    --ion-color-base-rgb: var(--ion-color-light-blue-rgb);
    --ion-color-contrast: var(--ion-color-light-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-light-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-light-blue-shade);
    --ion-color-tint: var(--ion-color-light-blue-tint);
  }

  --ion-color-turquoise: #469c80;
  --ion-color-turquoise-rgb: 70, 156, 128;
  --ion-color-turquoise-contrast: #ffffff;
  --ion-color-turquoise-contrast-rgb: 255, 255, 255;
  --ion-color-turquoise-shade: #3e8971;
  --ion-color-turquoise-tint: #59a68d;

  .ion-color-turquoise {
    --ion-color-base: var(--ion-color-turquoise);
    --ion-color-base-rgb: var(--ion-color-turquoise-rgb);
    --ion-color-contrast: var(--ion-color-turquoise-contrast);
    --ion-color-contrast-rgb: var(--ion-color-turquoise-contrast-rgb);
    --ion-color-shade: var(--ion-color-turquoise-shade);
    --ion-color-tint: var(--ion-color-turquoise-tint);
  }

  --ion-color-red: #d63838;
  --ion-color-red-rgb: 214, 56, 56;
  --ion-color-red-contrast: #ffffff;
  --ion-color-red-contrast-rgb: 255, 255, 255;
  --ion-color-red-shade: #bc3131;
  --ion-color-red-tint: #da4c4c;

  .ion-color-red {
    --ion-color-base: var(--ion-color-red);
    --ion-color-base-rgb: var(--ion-color-red-rgb);
    --ion-color-contrast: var(--ion-color-red-contrast);
    --ion-color-contrast-rgb: var(--ion-color-red-contrast-rgb);
    --ion-color-shade: var(--ion-color-red-shade);
    --ion-color-tint: var(--ion-color-red-tint);
  }

  --ion-color-blue: #0067b0;
  --ion-color-blue-rgb: 0, 103, 176;
  --ion-color-blue-contrast: #ffffff;
  --ion-color-blue-contrast-rgb: 0, 0, 0;
  --ion-color-blue-shade: #005d9e;
  --ion-color-blue-tint: #1a76b8;

  .ion-color-blue {
    --ion-color-base: var(--ion-color-blue);
    --ion-color-base-rgb: var(--ion-color-blue-rgb);
    --ion-color-contrast: var(--ion-color-blue-contrast);
    --ion-color-contrast-rgb: var(--ion-color-blue-contrast-rgb);
    --ion-color-shade: var(--ion-color-blue-shade);
    --ion-color-tint: var(--ion-color-blue-tint);
  }

  ion-button[disabled] {
    --opacity: 1;
    --ion-color-base: #7b8084;
  }
}

@media (prefers-color-scheme: dark) {
  /*
   * Dark Colors
   * -------------------------------------------
   */

  body {
    --ion-color-primary: #428cff;
    --ion-color-primary-rgb: 66, 140, 255;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3a7be0;
    --ion-color-primary-tint: #5598ff;

    --ion-color-secondary: #50c8ff;
    --ion-color-secondary-rgb: 80, 200, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #46b0e0;
    --ion-color-secondary-tint: #62ceff;

    --ion-color-tertiary: #6a64ff;
    --ion-color-tertiary-rgb: 106, 100, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #5d58e0;
    --ion-color-tertiary-tint: #7974ff;

    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47, 223, 117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255, 213, 52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255, 73, 97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f4f5f8;
    --ion-color-dark-rgb: 244, 245, 248;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0, 0, 0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    --ion-color-light: #222428;
    --ion-color-light-rgb: 34, 36, 40;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255, 255, 255;
    --ion-color-light-shade: #1e2023;
    --ion-color-light-tint: #383a3e;
  }
}
