$white: #ffffff;
$white-text: #fefefe;

$gray-border: #dfe6e8;
$gray-box-shadow: #dcdcdc;
$gray-background-active: #eff2f4;
$gray-buttons-text-color: #8b9195;
$gray-label-text-color: #8b9195;
$gray-card-focused: #8b9195;
$gray-background-form-grid: #eff2f4;
$gray-text-placeholder: #8b9195;
$gray-text-additional-info: #8b9195;
$gray-inactive-color: #8b9195;
$gray-text-color: #70767a;
$gray-checkbox: #c8cbcc;
$gray-timer-border: #c8cbcc;
$gray-background-form: #7d8f97;
$gray-main-screen-title: #778189;
$gray-main-screen-border: #dfe6e8;
$gray-light-main-screen-background: #eff2f4;
$gray-main-screen-bg: #f6f7f8;
$gray-button: #85979e;
$gray-auth-inputs-border: #e5ebec;
$gray-auth-label: #7b8084;
$gray-disabled-text: #c8cbcc;
$gray-disabled-btn: #7b8084;
$gray-text-filters: #7b8084;
$gray-control-text: #7b8084;
$gray-approval-item: #8b9195;
$gray-approval-border: #c2cbce;
$gray-page-number-background: #4c4c4c;
$gray-select-border: #8b9195;
$gray-no-delegating: #cbcdce;
$gray-disabled-input: #cbcdce;
$gray-user-card-background: #c4c4c4;
$gray-urgency-category: #6d7e84;
$gray-button-clear-text: #c5c8ca;
$gray-button-submit-disabled: #e2e6e8;
$gray-background-form-and-tap: #eff2f4;
$gray-background-headline: #e5e8ea;
$gray-background-inactive-tap: #dbdee0;
$gray-inactive-color-tap: #838997;
$gray-files-preloader-background: #e0e0e0bd;
$gray-date-text: #8b9195;
$gray-border-comment: #757b7b;
$gray-file-comment-border: #bcc2c4;
$gray-background-group: #d7dadb;
$gray-border-branch: #c7d3d6;
$gray-border-text-graphic: #7c8c94;
$gray-background-radio-button: #eff0f0;
$gray-radio-button: #8b9195;
$gray-button-disabled-background: #eef1f1;
$gray-button-disabled-text: #d7d7d7;

$light-gray: #f4f5f5;

$orange-comment-border: #ffc14d;
$orange-button: #f79d1e;
$orange-addressee-background: #ffd27f;
$orange-immediate-category: #f5980d;
$orange-text: #f5980d;
$orange-light-selected: #fffaf0;
$orange-text: #f5980d;
$orange-header-expedited-card: #faf3df;
$orange-background-radio-button: #fbf5e4;
$orange-radio-button: #f79d1e;

$red-checkbox: #e64c3c;
$red-comment-border: #ff6858;
$red-attention-text: #e64c3c;
$red-immediate-category: #e64c3c;
$red-auth-error: #e64c3c;
$red-text-error: #e64c3c;
$red-date-text: #e64c3c;
$red-date-border-error: #e64c3c;
$red-button-active-background: #e64c3c;
$red-button: #d63838;
$red-text-overdue: #da001a;
$red-background-color: #d63838;
$red-approval-not-agree: #ff7672;
$red-button-color: #da001a;
$red-text-unavailable-approval: #cb0935;

$blue-background-unseen: #018ccf;
$blue-text-unseen: #0067b0;
$blue-checkbox: #0067b0;
$blue-select: #0067b0;
$blue-button: #0067b0;
$blue-button-link: #0067b0;
$blue-button-background: #8ab4d6;
$blue-main-screen-corner-bg: #008bcb;
$blue-auth-highlight: #0067b0;
$blue-approval-border: #018ccf;
$blue-email-link: #0067b0;
$blue-report-link: #0067b0;
$expand-collapse-icon-color: #0067b0;
$lightblue-button-background: #75b5d8;
$lightblue-radio: #75b5d8;
$lightblue-radio-background: #d9ecf6;
$blue-background-in-route: #008bcb;
$blue-text-color: #008bcb;
$blue-bg-active-phase: #ebf4f9;
$blue-timer-background: #bbe6fa;
$blue-radio-border: #88bfe5;
$blue-box-shadow: #9aafb7;
$blue-header-expedited-card: #b6d3e6;
$blue-content-expedited-card: #c6e2f4;

$black-text-header-color: #22272b;
$black-text-color: #22272b;
$black-main-screen-menu: #243741;
$black-auth-text: #22272b;
$black-text-description: #2d3336;
$black-text-deadline-date: #283137;
$black-text-agreement: #1d1e3b;

$resolution-type-beige: #f5e6be;
$resolution-type-blue: #c6e2f9;
$resolution-type-orange: #ffd27f;
$resolution-type-white: #dcf8ed;

$resolution-question-types-white: #fdfaf2;
$resolution-question-types-blue: #f4faf8;
$resolution-question-types-brighter-blue: #f3f9fc;

$green-auth-success: #23775c;
$green-approval-border: #469c80;
$green-apply-button: #23775c;
$green-date-text: #4c8934;
$green-button: #23775c;
$green-addressee-background: #81b060;
$green-active-border: #23775c;
$green-background-radio-button: #eaf6f2;
$green-radio-button: #469c80;

$turquoise-button: #469c80;

$brown-button: #af9b78;
$brown-radio: #af9b78;
$brown-radio-background: #fbf4e3;

$clear-input-icon-color: #8a9ca8;
