/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
@import 'colors';

body {
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

.ng2-pdf-viewer-container {
  overflow-x: hidden !important;
}

#viewerContainer {
  background-color: rgb(232, 232, 235);
}

ion-refresher.refresher-active {
  z-index: 0;
}
.highlightText {
  color: #0070a4;
  &_dp {
    padding: 1px 2px;
    background: #ffd27f;
    color: black;
  }
}

.custom-spinner {
  --background: initial;
  transform: scale(4.5);
}

.searchbar-cancel-button {
  font-size: 14px !important;
  line-height: 16px;
  color: #0070a4 !important;
}

.searchbar-input {
  $font-size: 14px;
  &::-webkit-input-placeholder {
    font-size: $font-size;
  }
  &::-moz-placeholder {
    font-size: $font-size;
  }
  &:-ms-input-placeholder {
    font-size: $font-size;
  }
  &:-moz-placeholder {
    font-size: $font-size;
  }
}

.textarea-wrapper {
  width: 100%;
}

app-document-preview {
  .back-button {
    .button-text {
      letter-spacing: 0;
    }
  }
}

.user-actions-popover .popover-content {
  --min-width: min-content;
}

.agreement-item-popover {
  --width: 40%;
  --min-width: 400px;

  .popover-content {
    overflow: unset;
  }
}

ion-header ion-toolbar:first-of-type {
  padding-top: 0px;
}

ion-modal.confirm-action {
  left: 328px; // Ширина меню со списком ПД
  display: flex;
  align-items: flex-end;
  padding-bottom: 32px;
  --ion-backdrop-color: #ffffff;
  --ion-backdrop-opacity: 0.9;

  &::part(content) {
    height: 180px;
    max-width: 388px;
    background: inherit;
  }
}

body {
  font-family: 'Roboto-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen-Sans', 'Ubuntu', 'Cantarell',
    'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  overflow: hidden;
  :focus {
    outline: none;
  }
}

pre {
  font-family: 'Roboto-Regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen-Sans', 'Ubuntu', 'Cantarell',
    'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('/assets/fonts/Roboto-Bold.ttf');
  src: url('/assets/fonts/Roboto-Bold.woff');
}
@font-face {
  font-family: 'Roboto-Regular';
  src: url('/assets/fonts/Roboto-Regular.ttf');
  src: url('/assets/fonts/Roboto-Regular.woff');
}
@font-face {
  font-family: 'Roboto-Italic';
  src: url('/assets/fonts/Roboto-Italic.ttf');
  src: url('/assets/fonts/Roboto-Italic.woff');
}
@font-face {
  font-family: 'Roboto-Light';
  src: url('/assets/fonts/Roboto-Light.ttf');
  src: url('/assets/fonts/Roboto-Light.woff');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('/assets/fonts/Roboto-Medium.ttf');
  src: url('/assets/fonts/Roboto-Medium.woff');
}
@font-face {
  font-family: 'Roboto-Thin';
  src: url('/assets/fonts/Roboto-Thin.ttf');
  src: url('/assets/fonts/Roboto-Thin.woff');
}
@font-face {
  font-family: 'Roboto-Black';
  src: url('/assets/fonts/Roboto-Black.ttf');
  src: url('/assets/fonts/Roboto-Black.woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Roboto-Regular.ttf');
  src: url('/assets/fonts/Roboto-Regular.woff');
}

.edit-menu {
  --width: 23vw;
}

ion-item.side-bar {
  --padding-start: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-end: 0px;
  --inner-border-style: none;
  --inner-border-width: 0;

  &.active {
    --background: #eff2f4;
  }
}

ion-item.side-bar-expedited {
  --padding-start: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --inner-padding-end: 0px;
  --inner-border-style: none;
  --inner-border-width: 0;
}

ion-icon.collapse-icon {
  color: $expand-collapse-icon-color;
  margin: 0 12px;
  vertical-align: middle;
  font-size: 23px;
  &.expanded {
    transform: rotate(180deg);
  }
}

ion-toast {
  --background: #eff2f4;
  --border-style: solid;
  --box-shadow: 2px 2px 0px rgba(154, 175, 183, 0.2);
  --border-radius: 8px;
  --border-width: 1px;
  --border-color: #dfe6e8;

  &::part(header) {
    color: #22272b;
    margin: 10px 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
  }

  &::part(container) {
    align-items: flex-start;

    &::after {
      content: var(--time);
      margin: 8px;
      font-weight: 300;
      font-size: 14px;
      line-height: 24px;
      color: #22272b;
    }
  }

  &.urgently::part(header)::after {
    content: 'Срочно';
    background: #d63838;
    border-radius: 2px;
    margin: 8px;
    padding: 4px 8px;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    align-items: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #ffffff;
  }
}

.oog-file-viewer {
  border: none !important;
}

.oog-file-viewer .oog-file-viewer__header {
  display: none !important;
}

.oog-file-viewer .oog-file-viewer__content .item-content {
  box-shadow: 2px 0 8px 0 lightgrey !important;
}

ion-modal.modal-fullscreen::part(content) {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0;
}

ion-modal.custom-background {
  display: flex;
  align-items: flex-end;
  background: rgba(239, 242, 244, 0.85);

  &::part(content) {
    display: flex;
    align-items: flex-end;
    min-height: 385px;
    height: max-content;
    width: 630px;
    background: none;
  }
}

ion-modal.modal-new-folder::part(content),
ion-modal.modal-folder-page::part(content) {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100vh;
}

ion-modal.transparent-background::part(content) {
  width: 100%;
  height: 100%;
  background: transparent;
}

ion-modal.full-screen-with-background::part(content) {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
}

ion-modal.full-screen-with-background-gray::part(content) {
  width: 100%;
  height: 100%;
  background: rgba(239, 242, 244, 0.85);
}

ion-modal {
  &.default-small-modal,
  &.approve-modal,
  &.error-modal,
  &.start-development,
  &.refresh-route,
  &.confirm-cloud-sign,
  &.certificate-valid-error,
  &.error-document-version {
    --height: auto;
    --ion-backdrop-color: #ffffff;
    --ion-backdrop-opacity: 0.5;

    &::part(content) {
      border: 1px solid #dfe6e8;
      box-shadow: 0 0 0 2px rgba(154, 175, 183, 0.2);
      border-radius: 8px;
    }

    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;

      .modal-content {
        overflow: auto;
      }
    }
  }

  &.error-modal,
  &.default-small-modal {
    &::part(content) {
      max-width: 320px;
      padding: 20px 32px 24px;
    }

    .label {
      white-space: unset;
      overflow: unset;
    }
  }

  &.default-small-modal {
    &::part(content) {
      max-width: 290px;
    }
  }

  &.start-development {
    &::part(content) {
      max-width: 576px;
    }
  }

  &.refresh-route {
    &::part(content) {
      max-width: 424px;
    }
  }

  &.confirm-cloud-sign {
    &::part(content) {
      max-width: 440px;
    }
  }

  &.certificate-valid-error {
    &::part(content) {
      max-width: 350px;
      padding: 20px 32px 24px;
    }
  }

  &.error-document-version {
    &::part(content) {
      max-width: 330px;
    }
  }

  ion-button {
    height: 100%;
    width: 100%;
    --background: #0067b0;
  }
}

ion-button {
  font-family: 'Roboto-Regular' !important;
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
